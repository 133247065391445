.feed {
    flex: 0.5;
    border-right: 1px solid var(--twitter-background);
    min-width: fit-content;
    overflow-y: scroll;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .feed::-webkit-scrollbar {
    display: none;
  }

  .feed {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .feed__head {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border: 1px solid var(--twitter-background);
    padding: 15px 20px;
  }
  
  .feed__head > h2 {
    font-size: 18px;
    font-weight: 700;
  }